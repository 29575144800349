import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Head from "../components/head"

import Layout from "../components/layout"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle =<Link style={{ boxShadow: 'none', textDecoration:'underline' }} to={'/'}>
    {this.props.data.site.siteMetadata.title}</Link>
    // const url= window.location
    const shorturl=this.props.location.pathname

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Head title="Page Not Found"/>
        <section class="page_404">
        <div class="container">
          <div class="row">	
          <div class="col-sm-12 ">
          <div class="col-sm-10 col-sm-offset-1  text-center">
          <div class="four_zero_four_bg">
            <h1 class="text-center ">404</h1>
          
          
          </div>
          
          <div class="contant_box_404">
          <h3 class="h2">
          Look like you're lost
          </h3>
          
          <p>The page you are looking for is not avaible! ... or you may not have an internet connection!</p>
          <p>If you think otherwise, please <a href={"mailto:sunil.gareja@dbd.co.uk?cc=sunil_gareja@hotmail.co.uk&subject=eSign Help Guide - 404 - missing page  "+shorturl+"&body=Someone has reported a missing page on the eSign Help Guide.%0d%0dPage:  "+shorturl+"%0d"} rel="EMAIL">click here</a> to send an email to the IT department.</p>
          <Link to={'/'} className="link_404">Go to Home</Link>
        </div>
          </div>
          </div>
          </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
